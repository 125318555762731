
import { computed, defineComponent, reactive, toRefs, watch, ref, onMounted, onActivated } from 'vue'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import CreateExercise from './createMatching.vue'
import { useRouter } from 'vue-router'
import { fetchSchoolList, exerciseList, deleteExerciseList, exerciseShare } from '@/api/index'
import { EXERISE } from '@/hooks/config'
import { screenObj, showConfirm, setPage } from '@/hooks/common-hooks'
import { message } from 'ant-design-vue'
import previewDialog from '@/views/examManager/components/previewDialog.vue'
import shareDialog from '@/views/exerciseManager/components/shareDialog.vue'
import initSelect from '@/components/select.vue'
import { getInfo } from '@/api/cookie'
import checkListModal from '@/views/examManager/components/checkList.vue'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '习题列表'
  },
  {
    id: 2,
    btnName: '新建习题'
  }
]
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 90
  },
  {
    title: '习题标签',
    dataIndex: 'tag',
    key: 'tag',
    width: 120
  },
  {
    title: '习题类型',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'type' },
    width: 120
  },
  {
    title: '题干',
    dataIndex: 'text',
    key: 'text',
    width: '30%',
    ellipsis: true
  },
  {
    title: '备注',
    dataIndex: 'remarks',
    key: 'remarks',
    ellipsis: true
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right',
    width: 250
  }
]
const sharecolumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 80
  },
  {
    title: '习题标签',
    dataIndex: 'tag',
    key: 'tag',
    width: 120
  },
  {
    title: '题干',
    dataIndex: 'text',
    key: 'text',
    width: '30%',
    ellipsis: true
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right',
    width: 250
  }
]
export default defineComponent({
  name: 'ExerciseManager',
  components: {
    mangerPageHeader,
    initSelect,
    previewDialog,
    checkListModal
    // shareDialog
    // CreateExercise
  },
  setup () {
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination },
      version: '',
      schoolId: null,
      typeId: null,
      EXERISE,
      search: '',
      visible: false, // 创建习题
      createType: null // 创建习题的类型
    })
    const exeriseList = ref(EXERISE)
    const schoolList = ref([])
    const btnIndex = ref(btnAndSearchHooks().btnIndex)
    const handleLayer = () => {
      tableData.visible = !tableData.visible
      btnIndex.value = 0
      tableData.createType = null
    }
    // 获取学校列表
    const fetchShoolLists = () => {
      fetchSchoolList().then(res => {
        schoolList.value = res.data
      })
    }
    // 获取习题列表
    const fetcheEerciseList = (isDelete?: boolean) => {
      exerciseList(screenObj({
        sid: tableData.schoolId,
        type: tableData.typeId,
        search: tableData.search.trim()
        // page: tableData.pagination.current,
        // pagesize: tableData.pagination.pageSize
      })).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetcheEerciseList()
        }
      })
    }
    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      fetcheEerciseList()
    }
    // 跳转对应的页面
    const handleRouter = () => {
      const name = tableData.EXERISE.find(item => item.id === tableData.createType)
      tableData.visible = false
      router.push({
        name: name?.router
      })
    }
    // 跳转对应的页面进行编辑
    const handleEdit = (record: any) => {
      const name = tableData.EXERISE.find(item => item.id === record.type)
      router.push({
        name: name?.router,
        params: { id: record.id }
      })
    }
    const changeIndexs = (index: number) => {
      if (index === 1) {
        handleLayer()
      }
    }
    // 删除习题
    const handleDelete = (record: any) => {
      showConfirm({
        content: '此操作不可逆，确定删除该习题吗?',
        callBack: () => {
          deleteExerciseList({
            id: record.id
          }).then(() => {
            message.success('删除习题成功')
            fetcheEerciseList(true)
          })
        }
      })
    }
    const getTypeName = (type: number) => {
      return EXERISE.filter(item => {
        return item.id === type
      })[0].name
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetcheEerciseList()
    }
    // 预览弹出层
    const previewVisible = ref(false)
    const previewId = ref(0)
    const openPreview = (id: number) => {
      previewId.value = id
      previewVisible.value = true
    }

    // 分享
    const checkList = ref([])
    const rowSelection = reactive({
      selectedRowKeys: [], // 指定选中项的 key 数组
      selectedRows: [],
      onChange: (selectedRowKeys: any, selectedRows: any) => {
        // console.log(selectedRowKeys, 'selectedRowKeys')
        // console.log(selectedRows, 'selectedRows')
        rowSelection.selectedRowKeys = selectedRowKeys
        rowSelection.selectedRows = selectedRows
        if (checkList.value.length === 0) {
          checkList.value = selectedRows
        } else {
          checkList.value = checkList.value.concat(selectedRows.filter((item: any) => !checkList.value.find((items: any) => items.id === item.id)))
        }
        checkList.value = checkList.value.filter((item: any) => selectedRowKeys.indexOf(item.id) !== -1)
      }
    })
    const hasSelected = computed(() => rowSelection.selectedRowKeys.length)
    const shareVisible = ref(false)
    const openShare = () => {
      if (hasSelected.value > 0) {
        shareVisible.value = true
      } else {
        message.error('请至少选择一项才可分享')
      }
    }
    const deleteCheckList = (index: number) => {
      const _list: any[] = checkList.value.splice(index, 1)
      const _index = rowSelection.selectedRowKeys.findIndex((item: any) => item === _list[0].id)
      rowSelection.selectedRowKeys.splice(_index, 1)
    }
    const getIds = (data: any) => {
      return data.map((item: any) => item.id)
    }
    const exerciseShareHttp = (sid: any, list: any) => {
      if (sid.value === null) {
        message.error('请先选择学校')
        return false
      }
      exerciseShare({
        school_id: parseInt(sid),
        exercise_ids: getIds(list)
      }).then(res => {
        message.success('分享成功')
      })
    }
    const saveSubmit = (sid: any) => {
      // 学校[school_id]
      console.log(sid, '选择的学校id')
      console.log(checkList.value, '选中的试卷列表')
      exerciseShareHttp(sid, checkList.value)
    }
    // 关闭弹框
    const dialogCancle = () => {
      shareVisible.value = false
      fetcheEerciseList()
    }
    const user: any = getInfo()
    const isSearch = JSON.parse(user)?.school_id === 1
    const getIsSelectRow = () => {
      if (isSearch) {
        return { ...rowSelection }
      } else {
        return null
      }
    }
    onMounted(() => {
      fetchShoolLists()
    })
    onActivated(() => {
      fetcheEerciseList()
    })
    return {
      sharecolumns,
      checkList,
      hasSelected,
      deleteCheckList,
      saveSubmit,
      isSearch,
      rowSelection,
      dialogCancle,
      shareVisible,
      openShare,
      getIsSelectRow,
      openPreview,
      previewId,
      previewVisible,
      getTypeName,
      btnList,
      btnIndex,
      changeIndexs,
      ...toRefs(tableData),
      changePage,
      schoolList,
      fetcheEerciseList,
      handleRouter,
      handleLayer,
      handleEdit,
      handleDelete,
      fetchSearch,
      exeriseList
    }
  }
})
